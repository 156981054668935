<template>
  <div class="release">
    <form class="pageform">
      <div>
        <label>套餐内容：</label>
        <input v-model="submitForm.setMealContent" @click="showMeal = true" />
      </div>
      <div>
        <label>直降金额：</label>
        <input v-model="submitForm.downAmount" disabled />
      </div>
      <div>
        <label>办理手机号码：</label>
        <input v-model="submitForm.mobileNo" />
      </div>
      <p class="submit" @click="submitMeds" loading>登记下发礼品卡</p>
    </form>
    <ul class="pagetab">
      <li>下发礼品卡</li>
      <router-link tag="li" :to="{ name: 'ShantouReleaseRecord' }"
        >登记记录</router-link
      >
    </ul>
    <van-action-sheet
      v-model="showMeal"
      :actions="sealLists"
      close-on-click-action
      cancel-text="取消"
      @cancel="showMeal = false"
      @select="onSealSelect"
    />
  </div>
</template>

<script>
import { reqGetMealInfo, reqCreateCoupon } from '@/api/shantou-project'
export default {
  name: 'ShantouReleasePage',
  data() {
    return {
      submitForm: {},
      showMeal: false, // 显示套餐
      sealLists: []
    }
  },
  async created() {
    const { data } = await reqGetMealInfo()
    const RefactorData = data.map(item => {
      item.name = item.setMealContent
      return item
    })
    this.sealLists = RefactorData
  },
  methods: {
    onSealSelect(item) {
      this.submitForm = { ...this.submitForm, ...item }
    },
    async submitMeds() {
      const reg = /^1[3456789]\d{9}$/
      if (this.submitForm.mobileNo && reg.test(this.submitForm.mobileNo)) {
        const loading1 = this.$toast.loading({
          message: '下发中...',
          forbidClick: true,
          loadingType: 'spinner'
        })
        this.submitForm.token =
          sessionStorage.getItem('shantouToken') || undefined
        const data = await reqCreateCoupon(this.submitForm)
        loading1.clear()
        this.$toast.success(data.message)
        this.submitForm = {}
      } else {
        this.$toast.fail('请填写正确的办理手机号')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.release {
  position: relative;
  height: 100vh;
  .pageform {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    width: 77.33vw;
    margin: auto;
    & > div {
      display: flex;
      margin-bottom: 8vw;
      line-height: 9.3vw;
      & > label {
        width: 26.13vw;
        font-size: 3.73vw;
        color: #9d9d9d;
      }
      & > input {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 5vw;
        line-height: 9.3vw;
        border-radius: 10.4vw;
        background: #fafafa;
      }
    }
    .submit {
      width: 77.33vw;
      line-height: 13.3vw;
      background: linear-gradient(134deg, #3d6efd 0%, #3d6efd 100%);
      box-shadow: 0px 6px 13.5px 0px #beceff;
      border-radius: 10.4vw;
      margin: 26.6vw auto 0;
      text-align: center;
      color: #fff;
      font-size: 4vw;
    }
  }
  .pagetab {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    line-height: 9vh;
    display: flex;
    font-size: 3.73vw;
    & > li {
      flex: 1;
      text-align: center;
      background: #3d6efd;
      color: #fff;
      text-align: center;
      &:last-child {
        background: #fff;
        color: #3d6efd;

        border: 1px solid #3d6efd;
      }
    }
  }
}
</style>
